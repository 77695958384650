import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../../JointSettings.js';

import JointItem from '../../items/JointItem.js';
import JointItemConf from '../../items/JointItemConf.js';
import { JointItemTypes, JointBoardTypes } from '../../items/JointTypes.js';
import JointUtils from '../../utils/JointUtils.js';

import JointHoursAxis from './JointHoursAxis.js';
import JointResourcesAxis from './JointResourcesAxis.js';

class JointResourcesPlannerBoard {
	constructor(jointMain, currentDate, resources, resourceAllocations, resourceTypes, resourceBlocks) {
		this.jointMain = jointMain;
		this.JointBoardType = JointBoardTypes.ResourcePlanner;
		this.jointMain.jointBoard = this;

		this.topAxisHeight = JointSettings.axis.bollardHeight;
		this.leftAxisWidth = JointSettings.axis.dayOfWeekWidth * 1.75;

		this.jointTopAxis = new JointHoursAxis(jointMain, this.topAxisHeight, this.leftAxisWidth, currentDate);
		this.jointLeftAxis = new JointResourcesAxis(jointMain, this.topAxisHeight, this.leftAxisWidth, resources);

		this.jointTopAxis.render();
		this.jointLeftAxis.render();

		this.leftBoundary = this.leftAxisWidth;
		this.rightBoundary = this.leftAxisWidth + 24 * this.jointTopAxis.topAxisItemWidth;
		this.roundedLeftBoundary = Math.round(this.leftBoundary);
		this.roundedRightBoundary = Math.round(this.rightBoundary);

		this.currentDate = currentDate;

		this.addPatterns();
		this.renderItems(resourceAllocations, resourceTypes, resourceBlocks);

		//var link = new shapes.standard.Link();
		//link.source(resource1.rectangle);
		//link.target(resource2.rectangle);
		//link.addTo(this.graph);
		//this.jointMain.paper.scale(0.5, 0.5);
		//this.jointMain.paper.translate(300, 50);
	}

	addPatterns() {
		let microWhitePattern = V(
			'<pattern id="backgroundMicroWhitePattern" x="0" y="0" width="120" height="800" patternUnits="userSpaceOnUse">' +
				'<rect fill="white" width="210" height="800" opacity="0.5"></rect>' +
				'<line x1="-140" y1="-5" x2="300" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="-120" y1="-5" x2="320" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="-100" y1="-5" x2="340" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="-80" y1="-5" x2="360" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="-60" y1="-5" x2="380" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="-40" y1="-5" x2="400" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="-20" y1="-5" x2="420" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="0" y1="-5" x2="440" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="20" y1="-5" x2="460" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="40" y1="-5" x2="480" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="60" y1="-5" x2="500" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="80" y1="-5" x2="520" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="100" y1="-5" x2="540" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="120" y1="-5" x2="560" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'<line x1="140" y1="-5" x2="580" y2="500" stroke="white" stroke-width="8" opacity="0.7" />' +
				'</pattern>'
		);

		V(this.jointMain.paper.svg).defs().append(microWhitePattern);

		let smallBlackPattern = V(
			'<pattern id="backgroundSmallBlackPattern" x="0" y="0" width="210" height="800" patternUnits="userSpaceOnUse">' +
				'<line x1="-210" y1="-10" x2="300" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="-180" y1="-10" x2="330" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="-150" y1="-10" x2="360" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="-120" y1="-10" x2="390" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="-90" y1="-10" x2="420" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="-60" y1="-10" x2="450" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="-30" y1="-10" x2="480" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="0" y1="-10" x2="510" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="30" y1="-10" x2="540" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="60" y1="-10" x2="570" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="90" y1="-10" x2="600" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="120" y1="-10" x2="630" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="150" y1="-10" x2="660" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="180" y1="-10" x2="690" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'<line x1="210" y1="-10" x2="720" y2="500" stroke="black" stroke-width="10" opacity="0.1" />' +
				'</pattern>'
		);

		V(this.jointMain.paper.svg).defs().append(smallBlackPattern);
	}

	renderItems(resourceAllocations, resourceTypes, resourceBlocks) {
		this.boardItems = [];

		// resourceAllocations
		for (let resourceAllocation of resourceAllocations) {
			let lane = this.jointLeftAxis.getYpositionFromResourceId(resourceAllocation.resourceid);

			// planned & auth dates
			if (
				lane != null &&
				//this.isFrontalItem(resourceAllocation) &&
				(this.rendersToday(resourceAllocation.plannerStartDate) || this.rendersToday(resourceAllocation.plannerEndDate))
			) {
				let resourceAllocationStartDateX = this.jointTopAxis.getXpositionFromDate(resourceAllocation.extendedPlannerStartDate);
				let resourceAllocationEndDateX = this.jointTopAxis.getXpositionFromDate(resourceAllocation.extendedPlannerEndDate);
				let itemWidth = resourceAllocationEndDateX - resourceAllocationStartDateX;

				resourceAllocation.plannerMobilizationPx = (resourceAllocation.plannerMobilizationTime * this.jointTopAxis.getPixelsPerHour()) / 60;
				resourceAllocation.plannerDemobilizationPx =
					(resourceAllocation.plannerDemobilizationTime * this.jointTopAxis.getPixelsPerHour()) / 60;

				if (itemWidth > 0) {
					let jsItemConf = new JointItemConf(
						JointItemTypes.ResourceAllocation,
						this.getResourceCodeLabel(resourceAllocation),
						this.leftAxisWidth + resourceAllocationStartDateX,
						this.topAxisHeight + this.jointLeftAxis.itemHeight * lane + 16,
						itemWidth,
						this.jointLeftAxis.itemHeight - 28
					);

					let item = new JointItem(jsItemConf, this.jointMain, resourceAllocation);
					this.boardItems.push(item);
					item.render();

					this.jointMain.jointZManager.toFront(item);
				}
			}

			// actual dates
			if (
				lane != null &&
				this.hasBackgroundItem(resourceAllocation) &&
				(this.rendersToday(resourceAllocation.actualstartdate) || this.rendersToday(resourceAllocation.actualenddate))
			) {
				let resourceActualAllocationStartDateX = this.jointTopAxis.getXpositionFromDate(resourceAllocation.actualstartdate);
				let resourceActualAllocationEndDateX = this.jointTopAxis.getXpositionFromDate(resourceAllocation.actualenddate);
				let actualItemWidth = resourceActualAllocationEndDateX - resourceActualAllocationStartDateX;

				if (actualItemWidth > 0) {
					let jsItemConf = new JointItemConf(
						JointItemTypes.ResourceActualAllocation,
						this.getResourceCodeLabel(resourceAllocation),
						this.leftAxisWidth + resourceActualAllocationStartDateX,
						this.topAxisHeight + this.jointLeftAxis.itemHeight * lane + 8,
						actualItemWidth,
						this.jointLeftAxis.itemHeight - 20
					);

					let actualItem = new JointItem(jsItemConf, this.jointMain, resourceAllocation);
					actualItem.render();

					this.jointMain.jointZManager.toFront(actualItem);
				}
			}
		}

		// resourceBlocks
		for (let resourceBlock of resourceBlocks) {
			let blockStartDateX = this.jointTopAxis.getXpositionFromDate(resourceBlock.plannerStartDate);
			let blockEndDateX = this.jointTopAxis.getXpositionFromDate(resourceBlock.plannerEndDate);
			let itemWidth = blockEndDateX - blockStartDateX;

			let lane = this.jointLeftAxis.getYpositionFromResourceId(resourceBlock.resourceid);

			if (lane != null) {
				let jsItemConf = new JointItemConf(
					JointItemTypes.ResourceBlock,
					'',
					this.leftAxisWidth + blockStartDateX,
					this.topAxisHeight + this.jointLeftAxis.itemHeight * lane,
					itemWidth,
					this.jointLeftAxis.itemHeight
				);

				let item = new JointItem(jsItemConf, this.jointMain, resourceBlock);
				this.boardItems.push(item);
				item.render();

				// full day block
				if (blockStartDateX == 0) {
					this.jointMain.jointZManager.toFront(item);
				}
			}
		}
	}

	rendersToday(date) {
		return new Date(date).getDate() == this.currentDate.getDate();
	}

	hasBackgroundItem(resourceAllocation) {
		return (
			resourceAllocation.resourceallocationstatus == 'PR' ||
			resourceAllocation.resourceallocationstatus == 'FI' ||
			resourceAllocation.resourceallocationstatus == 'VA'
		);
	}

	getResourceCodeLabel(resourceAllocation) {
		let vesselname = resourceAllocation.vesselname ? resourceAllocation.vesselname : '';
		let portcallnumber = resourceAllocation.portcallnumber ? '(' + resourceAllocation.portcallnumber + ')' : '';
		return vesselname + '\n' + portcallnumber;
	}
}

export default JointResourcesPlannerBoard;

import { JointBoardTypes, JointItemTypes } from '../items/JointTypes.js';

class JointZManager {
	// 9000 - 9999 // Axis Items

	constructor() {
		this.AxisItems = []; // 9500 - 9999
		this.BoardAssetItems = []; // 9000 - 9499

		this.StopItems = []; // 5000 - 5999
		this.ResourceAllocationItems = []; // 5000 - 5999

		this.BookingItems = []; // 3000 - 3999
		this.ResourceAllocationActualItems = []; // 3000 - 3999

		this.BerthBlockItems = []; // 1000 - 1999
		this.ResourceBlockItems = []; // 1000 - 1999
	}

	setItem(item) {
		if (
			item.operationsModel.itemType == JointItemTypes.Berth ||
			item.operationsModel.itemType == JointItemTypes.Bollard ||
			item.operationsModel.itemType == JointItemTypes.BerthLimit ||
			item.operationsModel.itemType == JointItemTypes.DayOfWeek ||
			item.operationsModel.itemType == JointItemTypes.Month ||
			item.operationsModel.itemType == JointItemTypes.HourOfDay ||
			item.operationsModel.itemType == JointItemTypes.DayForecast ||
			item.operationsModel.itemType == JointItemTypes.ResourceHour ||
			item.operationsModel.itemType == JointItemTypes.ResourceName ||
			item.operationsModel.itemType == JointItemTypes.ResourceCode
		) {
			let zIndex = 9500 + this.AxisItems.length;
			this.AxisItems.push([item, zIndex]);
			item.setZ(zIndex);
		}

		// --

		if (item.operationsModel.itemType == JointItemTypes.Vessel) {
			let zIndex = 5000 + this.StopItems.length;
			this.StopItems.push([item, zIndex]);
			item.setZ(zIndex);
		}

		if (item.operationsModel.itemType == JointItemTypes.ResourceAllocation) {
			let zIndex = 5000 + this.ResourceAllocationItems.length;
			this.ResourceAllocationItems.push([item, zIndex]);
			item.setZ(zIndex);
		}

		// --

		if (item.operationsModel.itemType == JointItemTypes.Booking) {
			let zIndex = 3000 + this.BookingItems.length;
			this.BookingItems.push([item, zIndex]);
			item.setZ(zIndex);
		}

		if (item.operationsModel.itemType == JointItemTypes.ResourceActualAllocation) {
			let zIndex = 3000 + this.ResourceAllocationActualItems.length;
			this.ResourceAllocationActualItems.push([item, zIndex]);
			item.setZ(zIndex);
		}

		// --

		if (item.operationsModel.itemType == JointItemTypes.BerthBlock) {
			let zIndex = 1000 + this.BerthBlockItems.length;
			this.BerthBlockItems.push([item, zIndex]);
			item.setZ(zIndex);
		}

		if (item.operationsModel.itemType == JointItemTypes.ResourceBlock) {
			let zIndex = 1000 + this.ResourceBlockItems.length;
			this.ResourceBlockItems.push([item, zIndex]);
			item.setZ(zIndex);
		}
	}

	async toFront(boardItem) {
		const self = this;
		new Promise((resolve) => {
			setTimeout(() => {
				self.setItem(boardItem);
				resolve();
			}, 400);
		});
	}

	axisToFront() {
		for (const item of this.AxisItems) {
			item[0].rectangle.toFront();
		}
	}
}

export default JointZManager;

export const JointBoardTypes = Object.freeze({
	// Berth planner
	BollardsBerth: Symbol('bollardsBerth'),

	// Resource planner
	ResourcePlanner: Symbol('resourcePlanner')
});

export const JointItemTypes = Object.freeze({
	// Debug
	Debug: Symbol('debug'),

	// Bollards Berth planner
	Bollard: Symbol('bollard'),
	Berth: Symbol('berth'),
	BerthLimit: Symbol('berthLimit'),

	// Berth planner common
	DayOfWeek: Symbol('dayOfWeek'),
	Month: Symbol('month'),
	HourOfDay: Symbol('hourOfDay'),
	DayForecast: Symbol('dayForecast'),
	Vessel: Symbol('vessel'),
	BerthBlock: Symbol('berthBlock'),
	Booking: Symbol('booking'),

	// Resource planner
	ResourceHour: Symbol('resourceHour'),
	ResourceName: Symbol('resourceName'),
	ResourceCode: Symbol('resourceCode'),
	ResourceAllocation: Symbol('resourceAllocation'),
	ResourceActualAllocation: Symbol('resourceActualAllocation'),
	ResourceBlock: Symbol('resourceBlock')
});

import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../JointSettings.js';

import { JointBoardTypes, JointItemTypes } from './JointTypes.js';
import JointCustomRectangle from './JointCustomRectangle.js';
import JointUtils from '../utils/JointUtils.js';

class JointItem {
	constructor(jsItemConf, jointMain, itemModel) {
		this.operationsModel = jsItemConf;
		this.jointMain = jointMain;

		this.operationsModel.draggable = false;
		this.operationsModel.notTransformable = true;

		switch (this.operationsModel.itemType) {
			case JointItemTypes.Bollard: {
				this.operationsModel.bollard = itemModel;
				break;
			}
			case JointItemTypes.Vessel: {
				this.operationsModel.stop = itemModel;
				if (!this.jointMain.readOnly && this.jointMain.userFunctionalities.has_EDIT_STOP) {
					this.operationsModel.draggable = itemModel.statusid != 'IN' && itemModel.statusid != 'FI';
					this.operationsModel.notTransformable = itemModel.statusid == 'IN' || itemModel.statusid == 'FI';
				}
				this.operationsModel.validBerth = true;
				this.operationsModel.board = {};
				break;
			}
			case JointItemTypes.BerthBlock: {
				this.operationsModel.berthblock = itemModel;

				//if (!this.jointMain.readOnly && new Date(this.operationsModel.berthblock.dateini) > new Date(Date.now())) {
				if (!this.jointMain.readOnly && this.jointMain.userFunctionalities.has_UPDATE_BERTHBLOCK) {
					this.operationsModel.draggable = true;
					this.operationsModel.notTransformable = false;
				}
				this.operationsModel.validBerth = true;
				this.operationsModel.board = {};
				break;
			}
			case JointItemTypes.Booking: {
				this.operationsModel.booking = itemModel;
				if (!this.jointMain.readOnly && this.jointMain.userFunctionalities.has_WRITE_BOOKING) {
					this.operationsModel.draggable = true;
					this.operationsModel.notTransformable = false;
				}
				this.operationsModel.validBerth = true;
				this.operationsModel.board = {};
				break;
			}
			case JointItemTypes.ResourceAllocation: {
				this.operationsModel.resourceallocation = itemModel;
				if (!this.jointMain.readOnly && this.jointMain.userFunctionalities.has_EDIT_RESOURCEALLOCATION) {
					this.operationsModel.draggable = itemModel.resourceallocationstatus == 'PL' || itemModel.resourceallocationstatus == 'AU';
					this.operationsModel.notTransformable = itemModel.resourceallocationstatus != 'PL' && itemModel.resourceallocationstatus != 'AU';
				}
				this.operationsModel.validAllocation = true;
				this.operationsModel.board = {};
				break;
			}

			case JointItemTypes.ResourceActualAllocation: {
				this.operationsModel.resourceallocation = itemModel;
				this.operationsModel.board = {};
				break;
			}

			case JointItemTypes.ResourceBlock: {
				this.operationsModel.resourceblock = itemModel;

				this.operationsModel.draggable = false;
				this.operationsModel.notTransformable = true;

				this.operationsModel.validAllocation = true;
				this.operationsModel.board = {};
				break;
			}
		}

		//this.rectangle = new shapes.standard.Rectangle();
		//this.rectangle = new JointCustomRectangle();
		this.rectangle = JointCustomRectangle.createNew(this.operationsModel);
		this.rectangle.position(this.operationsModel.position.x, this.operationsModel.position.y);
		this.rectangle.resize(this.operationsModel.size.width, this.operationsModel.size.height);

		this.rectangle.attr('label/text', this.operationsModel.label);

		switch (this.operationsModel.itemType) {
			case JointItemTypes.Bollard: {
				this.rectangle.attr('label/ref-x', -this.jointMain.jointBoard.jointTopAxis.bollardItemWidth / 2);
				break;
			}
			case JointItemTypes.HourOfDay: {
				this.rectangle.attr('label/ref-y', -this.jointMain.jointBoard.jointLeftAxis.hourOfDayHeight / 2);
				break;
			}
			case JointItemTypes.ResourceHour: {
				this.rectangle.attr('label/ref-x', -this.jointMain.jointBoard.jointTopAxis.topAxisItemWidth / 2);
				break;
			}
			case JointItemTypes.Vessel: {
				this.calculateBerthingDirection();
				break;
			}
			case JointItemTypes.ResourceAllocation: {
				this.setSlackImages();
				break;
			}
		}
	}

	render() {
		this.rectangle.addTo(this.jointMain.graph);
		this.itemView = this.rectangle.findView(this.jointMain.paper);

		let zBoard = 500;
		this.rectangle.set('z', zBoard);

		this.itemView.model.operationsModel = this.operationsModel;
		this.setLabel(this.operationsModel.label);

		if (this.operationsModel.itemType == JointItemTypes.Vessel) {
			this.setSecondLabel(JointUtils.getSecondLabelFromStop(this.operationsModel.stop));
			this.setLabel(JointUtils.getLabelFromStop(this.operationsModel.stop));
		}

		if (this.operationsModel.itemType == JointItemTypes.BerthBlock) {
			this.setSecondLabel(JointUtils.getSecondLabelFromBlock(this.operationsModel.berthblock));
			this.setLabel(JointUtils.getLabelFromBlock(this.operationsModel.berthblock));
		}

		if (this.operationsModel.itemType == JointItemTypes.Booking) {
			this.setSecondLabel(JointUtils.getSecondLabelFromBooking(this.operationsModel.booking));
			this.setLabel(JointUtils.getLabelFromBooking(this.operationsModel.booking));
		}

		this.resizeImages();
	}

	refresh() {
		this.itemView.model.remove();
		this.render();
	}

	// PROPERTIES

	setLabel(label) {
		this.operationsModel.label = label;
		this.rectangle.attr('label/text', label);

		if (
			this.operationsModel.itemType == JointItemTypes.Vessel ||
			this.operationsModel.itemType == JointItemTypes.BerthBlock ||
			this.operationsModel.itemType == JointItemTypes.Booking
		) {
			this.rectangle.attr('label/font-size', this.getVesselFontSize(this.itemView.model.attributes.size));
			this.rectangle.attr('label/text', this.trimSentence(this.itemView.model.attributes.size.width, label));
			this.rectangle.attr('label/ref-y', Math.min(-8, this.itemView.model.attributes.size.height / 8));

			if (this.itemView.model.attributes.size.height < 40) {
				this.rectangle.attr(
					'label/text',
					this.trimSentence(this.itemView.model.attributes.size.width, label + ' ' + this.operationsModel.secondLabel)
				);
				this.rectangle.attr('label/ref-y', 0);
			}
		}

		this.rectangle.attr('label/z', 9990);
	}

	setSecondLabel(label) {
		this.operationsModel.secondLabel = label;
		this.rectangle.attr('secondLabel/text', label);

		if (
			this.operationsModel.itemType == JointItemTypes.Vessel ||
			this.operationsModel.itemType == JointItemTypes.BerthBlock ||
			this.operationsModel.itemType == JointItemTypes.Booking
		) {
			this.rectangle.attr('secondLabel/font-size', this.getVesselFontSize(this.itemView.model.attributes.size) * 0.75);
			this.rectangle.attr('secondLabel/text', this.trimSentence(this.itemView.model.attributes.size.width, label));

			this.rectangle.attr('secondLabel/ref-y', Math.min(8, this.itemView.model.attributes.size.height / 8));

			if (this.itemView.model.attributes.size.height < 40) {
				this.rectangle.attr('secondLabel/text', '');
			}
		}
	}

	getVesselFontSize(size) {
		let newSizeFromWidth = size.width / 10;
		newSizeFromWidth = Math.max(15, newSizeFromWidth);
		newSizeFromWidth = Math.min(30, newSizeFromWidth);

		let newSizeFromHeight = size.height / 10;
		newSizeFromHeight = Math.max(15, newSizeFromHeight);
		newSizeFromHeight = Math.min(30, newSizeFromHeight);

		let fontSize = (newSizeFromWidth + newSizeFromHeight) / 2;

		return fontSize;
	}

	trimSentence(width, string) {
		const charCounter = string.length;
		const charPxFactor = 12;

		if (string.length > 12 && width < charCounter * charPxFactor) {
			const trimmedChars = Math.round((charCounter * charPxFactor - width) / charPxFactor);
			let final = string.substring(0, string.length - trimmedChars);
			return final.length > 0 ? final + '...' : final;
		} else {
			return string;
		}
	}

	setOriginalColor(color) {
		if (this.operationsModel.stop) {
			this.rectangle.attr('body/fill', color || this.operationsModel.stop.statusColor);
			this.rectangle.attr('body/stroke', color || this.operationsModel.stop.statusOutlineColor);
			this.rectangle.attr('label/fill', 'white');
			this.rectangle.attr('secondLabel/fill', 'white');
		}

		if (this.operationsModel.resourceallocation) {
			this.rectangle.attr('body/fill', this.operationsModel.resourceallocation.statuscolor);
			this.rectangle.attr('body/stroke', this.operationsModel.resourceallocation.statusoutlinecolor);
			this.rectangle.attr('label/fill', 'black');
			this.rectangle.attr('secondLabel/fill', 'black');
		}

		this.rectangle.attr('body/fillOpacity', '1');
	}

	setModifiedColor() {
		this.rectangle.attr('body/fill', JointSettings.colors.berthplanner.modified);
		this.rectangle.attr('body/stroke', JointSettings.colors.berthplanner.modified_border);
		this.rectangle.attr('label/fill', JointUtils.getLabelFillColorFromBackgroundColor(JointSettings.colors.berthplanner.modified));
		this.rectangle.attr('secondLabel/fill', JointUtils.getLabelFillColorFromBackgroundColor(JointSettings.colors.berthplanner.modified));
		this.rectangle.attr('body/fillOpacity', '1');
	}

	setInvalidColor() {
		this.rectangle.attr('body/fill', JointSettings.colors.berthplanner.invalid);
		this.rectangle.attr('body/fillOpacity', '0.5');
		this.rectangle.attr('body/stroke', JointSettings.colors.berthplanner.invalid_border);
		this.rectangle.attr('body/strokeWidth', '2');
		this.rectangle.attr('label/fill', 'black');
		this.rectangle.attr('secondLabel/fill', 'black');

		//this.setDropShadow();
	}

	setDropShadow() {
		this.rectangle.attr({
			label: {
				filter: {
					name: 'dropShadow',
					args: {
						dx: 1,
						dy: 1,
						blur: 1,
						color: JointUtils.getDropShadowColorFromBackgroundColor(this.initialAttributes.body.fill)
					}
				}
			}
		});
	}

	setZ(z) {
		this.rectangle.set('z', z);
	}

	getGRect() {
		return new g.Rect(
			this.itemView.model.attributes.position.x,
			this.itemView.model.attributes.position.y,
			this.itemView.model.attributes.size.width,
			this.itemView.model.attributes.size.height
		);
	}

	setSlackImages() {
		if (this.operationsModel.resourceallocation) {
			//this.rectangle.attr('leftSlack/xlink:href', require('../img/stripes.png'));
			this.rectangle.attr('leftSlack/fill', 'url(#backgroundMicroWhitePattern)');
			this.rectangle.attr('leftSlack/width', this.operationsModel.resourceallocation.plannerMobilizationPx);
			this.rectangle.attr('leftSlack/height', this.rectangle.attributes.size.height);
			this.rectangle.attr('leftSlack/x', 0);
			this.rectangle.attr('leftSlack/y', 0);
			//this.rectangle.attr('leftSlack/clip-path', 'inset(0% 20% round 1rem 0rem 0rem 1rem)');  esto para 40px - 8 en la x
			this.rectangle.attr(
				'leftSlack/clip-path',
				`inset(0% ${this.operationsModel.resourceallocation.plannerMobilizationPx / 100} round 0.5rem 0rem 0rem 0.5rem)`
			);

			//this.rectangle.attr('rightSlack/xlink:href', require('../img/stripes.png'));
			this.rectangle.attr('rightSlack/fill', 'url(#backgroundMicroWhitePattern)');
			this.rectangle.attr('rightSlack/width', this.operationsModel.resourceallocation.plannerDemobilizationPx);
			this.rectangle.attr('rightSlack/height', this.rectangle.attributes.size.height);
			this.rectangle.attr(
				'rightSlack/x',
				this.rectangle.attributes.size.width - this.operationsModel.resourceallocation.plannerDemobilizationPx
			);
			this.rectangle.attr('rightSlack/y', 0);
			//this.rectangle.attr('rightSlack/clip-path', 'inset(0% 20% round 0rem 1rem 1rem 0rem)'); esto para 40px -32 en la x
			this.rectangle.attr(
				'rightSlack/clip-path',
				`inset(0% ${this.operationsModel.resourceallocation.plannerDemobilizationPx / 100} round 0rem 0.5rem 0.5rem 0rem)`
			);

			if (!this.operationsModel.draggable) {
				this.rectangle.attr('leftSlack/cursor', 'default');
				this.rectangle.attr('rightSlack/cursor', 'default');
			}
		}
	}

	resizeImages() {
		if (this.operationsModel.itemType == JointItemTypes.Vessel) {
			this.calculateBerthingDirection(); // buscar el evento donde cambia para calcular solo ahí
			JointCustomRectangle.setInfoImage(this.rectangle);
			JointCustomRectangle.setBerthingImage(this.rectangle, this.operationsModel.stop.berthingImage);
		}

		this.setSlackImages();

		if (
			this.operationsModel.itemType == JointItemTypes.BerthBlock ||
			this.operationsModel.itemType == JointItemTypes.Booking ||
			this.operationsModel.itemType == JointItemTypes.ResourceBlock
		) {
			JointCustomRectangle.setBlockImage(this.rectangle);
		}
	}

	calculateBerthingDirection() {
		// RTL -> flechas right to left
		// LTR -> flechas left to right

		if (this.operationsModel.stop.berthingindanchoring == 1) {
			// fondeo
			this.operationsModel.stop.berthingImage = null;
			return;
		}

		if (this.operationsModel.stop.berthingtypecode.includes('P')) {
			// atracado de punta
			this.operationsModel.stop.berthingImage = null;
			return;
		}

		if (this.operationsModel.stop.berthseaside == 'L') {
			if (this.operationsModel.stop.berthingportstarboard == 'E' || this.operationsModel.stop.berthingportstarboard == null) {
				this.operationsModel.stop.berthingImage = 'LTR';
				return;
			}

			if (this.operationsModel.stop.berthingportstarboard == 'B') {
				this.operationsModel.stop.berthingImage = 'RTL';
				return;
			}
		}

		if (this.operationsModel.stop.berthseaside == 'R') {
			if (this.operationsModel.stop.berthingportstarboard == 'E' || this.operationsModel.stop.berthingportstarboard == null) {
				this.operationsModel.stop.berthingImage = 'RTL';
				return;
			}

			if (this.operationsModel.stop.berthingportstarboard == 'B') {
				this.operationsModel.stop.berthingImage = 'LTR';
				return;
			}
		}

		// default
		this.operationsModel.stop.berthingImage = null;
	}

	// END PROPERTIES
}

export default JointItem;

/*

// Listening for changes of the position to a single element
element1.on('change:position', function (element1, position) {
	alert('element1 moved to ' + position.x + ',' + position.y);
});
// All elements events are also propagated to the graph.
graph.on('change:position', function (element, position) {
	console.log('Element ' + element.id + 'moved to ' + position.x + ',' + position.y);
});
// Using the option parameter and a custom attribute
graph.on('change:custom', function (element, custom, opt) {
	if (opt.consoleOutput) {
		console.log('Custom attribute value changed to "' + custom + '"');
	}
});
element2.prop('custom', 'myValue', { consoleOutput: true });

addFreeTransform() {
	const itemView = this.itemView;
	this.jointMain.paper.on('element:pointerdown', function (elementView, evt) {
		if (elementView.model instanceof dia.Link) return;
		//if (itemView == 5) return;
		console.log(itemView);

		var freeTransform = new ui.FreeTransform({
			elementView: elementView,
			allowRotation: false,
			resizeDirections: ['top', 'bottom', 'left', 'right']
		});
		freeTransform.render();
	});
}

/// POPUPS

paper.on('element:pointerdown', (elementView) => {
	const popup = new ui.Popup({
		content: function (el) {
			const graph = new dia.Graph();
			const paper = new dia.Paper({
				width: 200,
				height: 100,
				gridSize: 1,
				model: graph
			});
			el.appendChild(paper.el);
			const r1 = new shapes.standard.Rectangle({
				position: { x: 10, y: 10 },
				size: { width: 50, height: 30 },
				attrs: { body: { fill: '#FE854F' }, label: { text: 'r1' } }
			});
			const r2 = new shapes.standard.Rectangle({
				position: { x: 90, y: 40 },
				size: { width: 50, height: 30 },
				attrs: { body: { fill: '#7C68FC' }, label: { text: 'r2' } }
			});
			const l = new shapes.standard.Link({ source: { id: r1.id }, target: { id: r2.id } });
			graph.addCells([r1, r2, l]);
		},
		target: elementView.el
	}).render();
});

*/

import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import { JointBoardTypes, JointItemTypes } from './items/JointTypes.js';
import JointSettings from './JointSettings.js';
import JointZManager from './utils/JointZManager.js';
import JointPopUp from './utils/JointPopUp.js';

class JointMain {
	constructor(containerid, $store, $puiEvents, $puiI18n, userFuncionalities, zoomFactor) {
		this.containerid = containerid;
		this.jointZManager = new JointZManager();
		this.$puiEvents = $puiEvents;
		this.$puiI18n = $puiI18n;
		this.storeState = $store._modules.root.state;
		this.userFuncionalities = userFuncionalities;
		this.zoomFactor = zoomFactor;

		let menuWidth = 64;
		if (this.storeState.menu.expandMenu || this.storeState.menu.secondMenuShowed) {
			menuWidth = 304;
		}

		this.paperWidth = window.innerWidth - menuWidth;
		this.paperHeight = window.innerHeight - 138;

		this.paperHeight = this.paperHeight * this.zoomFactor;

		this.initializePaper();
		this.drawPaper(this.paperHeight);
	}

	drawPaper() {
		let jointJsContainer = document.getElementById(this.containerid);

		if (jointJsContainer) {
			jointJsContainer.firstChild && jointJsContainer.firstChild.remove();
			jointJsContainer.append(this.paper.el);
		}
	}

	initializePaper() {
		const graph = (this.graph = new dia.Graph({}, { cellNamespace: shapes }));
		this.commandManager = new dia.CommandManager({ graph });

		var constraintElementView = dia.ElementView.extend({
			events: {
				mouseover: 'handleMouseOver'
			},

			handleMouseOver: function (evt, x, y) {
				// var size = this.model.get('size');
				// console.log(evt);
			}
		});

		this.paper = new dia.Paper({
			cellViewNamespace: shapes,
			//elementView: constraintElementView,
			width: this.paperWidth,
			height: this.paperHeight,
			gridSize: 1,
			/*drawGrid: {
				name: 'dot',
				color: JointSettings.colors.grid.dots
			},*/
			model: graph,
			background: { color: JointSettings.colors.paper },
			//defaultLink: new shapes.app.Link(),
			//defaultConnectionPoint: shapes.app.Link.connectionPoint,
			//interactive: { linkMove: false },
			//sorting: dia.Paper.sorting.APPROX,
			async: true
		});

		const self = this;
		this.paper.on('element:pointerdown', (cellView, evt) => {
			let isElement = cellView.model.isElement();
			let operationsModel = cellView.model.operationsModel;
			let isOperationsItem = operationsModel != undefined && operationsModel != null;

			// REMOVE DRAGGING FROM ELEMENTS

			let isNotDraggable = isElement && isOperationsItem && operationsModel.draggable == false;

			if (isNotDraggable) {
				cellView.preventDefaultInteraction(evt);
				/* if (evt.shiftKey) { cellView.preventDefaultInteraction(evt); }*/
			}

			// ADD FREE TRANSFORM TO ELEMENTS

			if (operationsModel.notTransformable == false) {
				let freeTransform;
				let resizeDirections;

				if (operationsModel.itemType == JointItemTypes.ResourceAllocation || operationsModel.itemType == JointItemTypes.ResourceBlock) {
					resizeDirections = ['left', 'right'];
				} else if (operationsModel.stop && operationsModel.stop.isTerminal == true) {
					resizeDirections = ['top', 'bottom'];
				} else {
					resizeDirections = ['top', 'left', 'bottom', 'right'];
				}

				freeTransform = new ui.FreeTransform({
					cellView: cellView,
					resizeDirections: resizeDirections,
					allowRotation: false
				});

				if (operationsModel.itemType == JointItemTypes.ResourceAllocation || operationsModel.itemType == JointItemTypes.ResourceBlock) {
					freeTransform.on('resize:start', function (evt) {
						this.jointPopUp = new JointPopUp(operationsModel, evt.originalEvent.target, 'resize', this.$puiI18n);
					});

					freeTransform.on('resize', function (evt) {
						this.jointPopUp.updateContent(operationsModel);
					});
				}

				freeTransform.render();
			}

			// ADD HALO

			let isBerthBlock = isElement && isOperationsItem && operationsModel.itemType == JointItemTypes.BerthBlock;

			if (isBerthBlock && self.userFuncionalities.has_UPDATE_BERTHBLOCK) {
				const halo = new ui.Halo({
					cellView: cellView,
					useModelGeometry: true,
					boxContent: false,
					handles: []
				});

				halo.addHandle({
					name: 'myaction',
					position: 'ne',
					icon: ' data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAkpJREFUWIXtV81OU1EQnpDyCJUAu9Z1qYCJUbsDNpDwDK1sBR6AxKcRa8uPJELcCN1QBS1PgIEAbhF3sEFnwlz7dXruuee2smOSLzRnvpn57jlzfiB6sHSWY7xmvGUcMo4V8ntVfbn7KDzF+My4ZfxJgHB2NaZvyzLWA4rGYYPxqNfiBcaZI+kB4w1jnvFMMa9jXx38c8ZY2uJFxqVJ9IkxERi7bWIv04iQKbuA4GvGQrj2f1bRWJyJbEjgpineTzPNGBEbSQHT1Dl1FfCNMF4yBjzxA8oZgbGKyen9oD0g7pjiVzpeYww6YmWsrhzhDoMPe2Ivrnie2vtc/hbB98J8hYjIgD8DxSM8B/+YyZ13CViE4KbxydSumQLvtXBGBaGvTt1L1QT/kkvAKhBWHH6cYhRhi9vZiWwFOFWXgG9AmHURYkQgqjHFxeaA13IRfgBhMiaJT8Q7T3Gxp8A96VeA636ok3t3BAsIXQLf5eQTgUvw3UUIaUK7E2rUvRxxIhKbMGkb2kJRt7t6okbd23Af/M5taA8ivL3sQVSnzoZzzQ4eRAUKOIjEGpBgG8blKP5N/ilGEVfUeR98hLyNuOJiM+YryuAbZZQo+TIqKTeyssk57RMgtgXk/30db4YEDTF+GhFlb4TbXjFuII88coLfh+PUvn6xJ0KeVXKL7pjYX4wn4drbInAmog7+Qnd7Wg6rScWsjjWp++kuD9tC2uKRyXJ8MAnToK9nOZo0U4PC/zERbmK392KPGct09w44YpwqWjq2rJwHC7a/O2tGUW8n05IAAAAASUVORK5CYII='
				});

				halo.on('action:myaction:pointerdown', function (evt) {
					evt.stopPropagation();

					cellView.model.remove();
					operationsModel.deleted = true;
					self.$puiEvents.$emit('berthplanner-berthBlock_removal', operationsModel.berthblock.id);
				});

				halo.render();
			}
		});
	}
}

export default JointMain;

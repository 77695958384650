<template>
	<div class="operations-legend" style="height: auto">
		<v-container>
			<v-row>
				<v-col class="pa-2 d-flex">
					<planner-legend-block
						v-for="(statusItem, index) in statusItems"
						:title="getTitle(statusItem)"
						:tooltip="getTooltip(statusItem)"
						:color="getColor(statusItem)"
						:key="`${lineUpByOperationTypeEnabled}_${index}`"
					/>

					<planner-legend-block
						v-if="userFunctionalities.has_LIST_BERTHBLOCK"
						class="float-left"
						:title="$t('form.berthblock.formtitle')"
						:tooltip="$t('form.berthblock.formtitle')"
						:image="'./block_legend.png'"
						:key="statusItems.length + 1"
					/>
					<planner-legend-block
						v-if="userFunctionalities.has_READ_BOOKING"
						class="float-left"
						:title="$t('form.booking.formtitle')"
						:tooltip="$t('form.booking.formtitle')"
						:image="'./booking_legend.png'"
						:key="statusItems.length + 2"
					/>

					<v-container class="pa-0 d-flex" v-if="isRepsolPeru">
						<v-switch v-model="internaLineUpByOperationTypeEnabled" class="pa-0 ml-2 mt-1 mb-n5"></v-switch>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-icon small class="mt-1 mr-2" v-on="on">fas fa-info-circle</v-icon>
							</template>
							<span>{{ $t('berthplanner.lineupbyoperationtype') }}</span>
						</v-tooltip>
					</v-container>

					<v-btn v-if="savingEnabled" class="mr-2" small text outlined @click.native="$parent.back()">{{ $t('form.cancel') }}</v-btn>
					<v-btn v-if="savingEnabled" small color="primary" @click.native="$parent.save()">{{ $t('form.save') }}</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import PlannerLegendBlock from './PlannerLegendBlock.vue';

export default {
	name: 'PlannerLegend',
	components: { PlannerLegendBlock },
	props: {
		legendType: {
			type: String
		},
		statusItems: {
			type: Array,
			required: true
		},
		lineUpByOperationTypeEnabled: {
			type: Boolean,
			default: true
		},
		savingEnabled: {
			type: Boolean,
			default: false
		},
		userFunctionalities: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			internaLineUpByOperationTypeEnabled: this.lineUpByOperationTypeEnabled
		};
	},
	computed: {
		currentLocation() {
			return this.$store.getters.getBerthPlannerConfiguration.currentLocation;
		},
		isRepsolPeru() {
			return this.currentLocation && this.currentLocation.currentDock && this.currentLocation.currentDock.description === 'REPSOL PERU';
		}
	},
	watch: {
		internaLineUpByOperationTypeEnabled() {
			this.$puiEvents.$emit('berthplanner-legend_lineUpSelected', this.internaLineUpByOperationTypeEnabled);
		}
	},
	methods: {
		getTitle(statusItem) {
			if (this.legendType == 'resourceplanner') {
				return statusItem.name.charAt(0) + statusItem.name.slice(1).toLowerCase();
			}

			if (this.legendType == 'berthplanner') {
				if (statusItem.stopstatusname) {
					return statusItem.stopstatusname.charAt(0) + statusItem.stopstatusname.slice(1).toLowerCase();
				} else {
					return statusItem.typedescription.charAt(0) + statusItem.typedescription.slice(1).toLowerCase();
				}
			}
		},
		getTooltip(statusItem) {
			if (this.legendType == 'resourceplanner') {
				return statusItem.name.charAt(0) + statusItem.name.slice(1).toLowerCase();
			}

			if (this.legendType == 'berthplanner') {
				if (statusItem.stopstatuscode) {
					return this.$t(`stop.status.${statusItem.stopstatuscode.toLowerCase()}.tooltip`);
				} else {
					return statusItem.typedescription.charAt(0) + statusItem.typedescription.slice(1).toLowerCase();
				}
			}
		},
		getColor(statusItem) {
			if (this.legendType == 'resourceplanner') {
				return statusItem.statuscolor;
			}

			if (this.legendType == 'berthplanner') {
				if (statusItem.colorhex) {
					return statusItem.colorhex;
				} else {
					return statusItem.color;
				}
			}
		}
	}
};
</script>

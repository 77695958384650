<template>
	<div v-resize="onResize">
		<div id="bp_container"></div>
	</div>
</template>

<script>
import JointMain from '../jointjs/JointMain.js';
import JointResourcesPlannerBoard from '../jointjs/components/resourcesplanner/JointResourcesPlannerBoard.js';
import { JointBoardTypes, JointItemTypes } from '../jointjs/items/JointTypes.js';
import JointPopUp from '../jointjs/utils/JointPopUp.js';
import moment from 'moment';

export default {
	props: {
		value: {
			type: Object,
			required: true
		},
		currentDate: {
			type: Date,
			required: true
		},
		currentResourceType: {
			type: Object,
			required: true
		},
		userFunctionalities: {
			type: Object,
			required: true
		},
		readOnly: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			debug: false,

			jointMain: null,
			jointBoard: null,
			boardType: null,

			resourceAllocations: [],
			resourceTypes: [],
			resourceBlocks: [],
			resources: [],

			resourceDialogStop: null,
			resourceDialog: false
		};
	},
	computed: {
		onPuiExpandMenu() {
			return this.$store.state.menu.expandMenu;
		},
		onPuiSecondMenuShowed() {
			return this.$store.state.menu.secondMenuShowed;
		}
	},
	watch: {
		value() {
			this.resourceAllocations = this.value.resourceAllocations;
			this.resourceTypes = this.value.resourceTypes;
			this.resourceBlocks = this.value.resourceBlocks;
			this.resources = this.value.resources;
			this.statusItems = this.value.statusItems;
		}
	},
	created() {
		this.resourceAllocations = this.value.resourceAllocations;
		this.resourceTypes = this.value.resourceTypes;
		this.resourceBlocks = this.value.resourceBlocks;
		this.resources = this.value.resources;
		this.statusItems = this.value.statusItems;

		this.$puiEvents.$on('resourceplanner-resourceDialog_update', (updatedStop) => {
			this.updateItemValuesFromVueEvent(updatedStop);
		});
	},
	async mounted() {
		this.setBoard();
	},
	destroyed() {
		//this.$puiEvents.$off('resourceplanner-resourceDialog_update');
	},
	methods: {
		setBoard() {
			this.jointMain = new JointMain('bp_container', this.$store, this.$puiEvents, this.$puiI18n, this.userFunctionalities);
			this.jointMain.userFunctionalities = this.userFunctionalities;
			this.jointMain.readOnly = this.readOnly;

			this.jointBoard = new JointResourcesPlannerBoard(
				this.jointMain,
				this.currentDate,
				this.resources,
				this.resourceAllocations,
				this.resourceTypes,
				this.resourceBlocks
			);

			const self = this;
			this.jointBoard.boardItems.forEach((boardItem) => {
				this.jointMain.paper.on('element:pointerdown', function (elementView, evt) {
					if (
						elementView.model.operationsModel.itemType == JointItemTypes.ResourceAllocation &&
						boardItem.operationsModel.itemType == JointItemTypes.ResourceAllocation &&
						elementView.model.operationsModel.resourceallocation.resourceallocationid ==
							boardItem.operationsModel.resourceallocation.resourceallocationid
					) {
						boardItem.jointPopUp = new JointPopUp(elementView.model.operationsModel, elementView.el, 'main', self.jointMain.$puiI18n);
					}

					if (
						elementView.model.operationsModel.itemType == JointItemTypes.ResourceBlock &&
						boardItem.operationsModel.itemType == JointItemTypes.ResourceBlock &&
						elementView.model.operationsModel.resourceblock.id == boardItem.operationsModel.resourceblock.id
					) {
						boardItem.jointPopUp = new JointPopUp(elementView.model.operationsModel, elementView.el, 'main', self.jointMain.$puiI18n);
					}

					if (elementView.model.operationsModel) {
						if (elementView.model.id == boardItem.itemView.model.id) {
							self.jointMain.jointZManager.toFront(boardItem);
						}
					}
				});

				boardItem.rectangle.on('change:position', (element, position) => {
					// POSITION RESTRICTIONS

					const roundedBoardItemLeftBoundary = Math.round(element.attributes.position.x);
					const roundedBoardItemWidth = Math.round(element.attributes.size.width);
					const roundedBoardItemRightBoundary = Math.round(element.attributes.position.x + roundedBoardItemWidth);
					this.debug && console.log('// change:position');
					this.debug && console.log('roundedLeftBoundary ' + this.jointBoard.roundedLeftBoundary);
					this.debug && console.log('roundedBoardItemLeftBoundary ' + roundedBoardItemLeftBoundary);
					this.debug && console.log('roundedBoardItemWidth ' + roundedBoardItemWidth);
					this.debug && console.log('roundedBoardItemRightBoundary ' + roundedBoardItemRightBoundary);
					this.debug && console.log('roundedRightBoundary ' + this.jointBoard.roundedRightBoundary);

					if (roundedBoardItemLeftBoundary < this.jointBoard.roundedLeftBoundary) {
						this.debug && console.log('// position restriction left limit');
						boardItem.rectangle.position(this.jointBoard.roundedLeftBoundary, element.attributes.position.y);
					}

					if (roundedBoardItemRightBoundary > this.jointBoard.roundedRightBoundary) {
						this.debug && console.log('// position restriction right limit');
						boardItem.rectangle.position(this.jointBoard.rightBoundary - roundedBoardItemWidth, element.attributes.position.y);
					}

					/*
					if (element.attributes.position.y != element.operationsModel.position.y) {
						boardItem.rectangle.position(element.attributes.position.x, element.operationsModel.position.y);
					}
					*/

					// END POSITION RESTRICTIONS

					boardItem.operationsModel.board.extendedPlannerStartDate = this.jointBoard.jointTopAxis.getDateFromXPosition(
						this.currentDate,
						element.attributes.position.x
					);
					boardItem.operationsModel.board.extendedPlannerEndDate = this.jointBoard.jointTopAxis.getDateFromXPosition(
						this.currentDate,
						element.attributes.position.x + element.attributes.size.width
					);

					boardItem.operationsModel.board.resource = this.jointBoard.jointLeftAxis.getClosestResourceFromYposition(
						element.attributes.position.y
					);

					if (boardItem.operationsModel.board.resource) {
						let lane = this.jointBoard.jointLeftAxis.getYpositionFromResourceId(boardItem.operationsModel.board.resource.resourceid);

						if (lane != null) {
							boardItem.operationsModel.board.lane = lane;
							boardItem.operationsModel.resourceallocation.resourceid = boardItem.operationsModel.board.resource.resourceid;
							boardItem.operationsModel.resourceallocation.resourcetypeid = boardItem.operationsModel.board.resource.resourcetypeid;
							boardItem.operationsModel.resourceallocation.resourcetype = boardItem.operationsModel.board.resource.type;
							boardItem.operationsModel.resourceallocation.description = boardItem.operationsModel.board.resource.description;

							boardItem.rectangle.position(
								element.attributes.position.x,
								this.jointBoard.topAxisHeight + this.jointBoard.jointLeftAxis.itemHeight * lane + 16
							);
						}
					} else {
						boardItem.rectangle.position(
							element.attributes.position.x,
							this.jointBoard.topAxisHeight + this.jointBoard.jointLeftAxis.itemHeight * boardItem.operationsModel.board.lane + 16
						);
					}

					boardItem.resizeImages();
					this.checkIntersections();
					this.updateItemValuesFromJointEvent(boardItem);
				});

				boardItem.rectangle.on('change:size', (element, size) => {
					// SIZE RESTRICTIONS

					const roundedBoardItemLeftBoundary = Math.round(element.attributes.position.x);
					const roundedBoardItemWidth = Math.round(size.width);
					const roundedBoardItemRightBoundary = Math.round(element.attributes.position.x + roundedBoardItemWidth);
					this.debug && console.log('// change:size');
					this.debug && console.log('roundedLeftBoundary ' + this.jointBoard.roundedLeftBoundary);
					this.debug && console.log('roundedBoardItemLeftBoundary ' + roundedBoardItemLeftBoundary);
					this.debug && console.log('roundedBoardItemWidth ' + roundedBoardItemWidth);
					this.debug && console.log('roundedBoardItemRightBoundary ' + roundedBoardItemRightBoundary);
					this.debug && console.log('roundedRightBoundary ' + this.jointBoard.roundedRightBoundary);

					// minimum width
					//if (size.width < this.jointBoard.jointTopAxis.topAxisItemWidth) {
					//	this.debug && console.log('// size restriction minimum limit');
					//	boardItem.rectangle.resize(this.jointBoard.jointTopAxis.topAxisItemWidth, size.height);
					//}

					// right overflow
					if (roundedBoardItemRightBoundary > this.jointBoard.roundedRightBoundary) {
						this.debug && console.log('// size restriction right overflow');
						boardItem.rectangle.position(
							this.jointBoard.roundedRightBoundary - roundedBoardItemWidth,
							element.operationsModel.position.y
						);
					}

					boardItem.resizeImages();

					// END SIZE RESTRICTIONS

					boardItem.operationsModel.board.extendedPlannerStartDate = this.jointBoard.jointTopAxis.getDateFromXPosition(
						this.currentDate,
						element.attributes.position.x
					);
					boardItem.operationsModel.board.extendedPlannerEndDate = this.jointBoard.jointTopAxis.getDateFromXPosition(
						this.currentDate,
						element.attributes.position.x + element.attributes.size.width
					);

					this.checkIntersections();
					this.updateItemValuesFromJointEvent(boardItem);
				});
			});

			this.checkIntersections();
		},
		checkIntersections() {
			this.jointBoard.boardItems.forEach((boardItem) => {
				boardItem.operationsModel.validAllocation = true;
				boardItem.setOriginalColor();
			});

			this.jointBoard.boardItems.forEach((boardItem1) => {
				this.jointBoard.boardItems.forEach((boardItem2) => {
					if (boardItem1 != boardItem2) {
						let intersection = boardItem1.getGRect().intersect(boardItem2.getGRect());
						if (intersection !== null && !(boardItem1.operationsModel.deleted || boardItem2.operationsModel.deleted)) {
							boardItem1.operationsModel.validAllocation = false;
							boardItem2.operationsModel.validAllocation = false;

							if (boardItem1.operationsModel.resourceallocation) boardItem1.setInvalidColor();

							if (boardItem2.operationsModel.resourceallocation) boardItem2.setInvalidColor();
						}
					}
				});
			});
		},
		updateItemValuesFromVueEvent(updatedStop) {
			/*
			this.jointBoard.boardItems.forEach((vessel) => {
				if (vessel.operationsModel.stop.id == updatedStop.id) {
					vessel.calculateBerthing();
				}
			});
			*/
		},
		updateItemValuesFromJointEvent(boardItem) {
			this.$parent.savingEnabled = true;

			if (boardItem.operationsModel.itemType == JointItemTypes.ResourceAllocation) {
				this.resourceAllocations.forEach((resourceAllocation) => {
					if (resourceAllocation.resourceallocationid == boardItem.operationsModel.resourceallocation.resourceallocationid) {
						if (boardItem.operationsModel.board.extendedPlannerStartDate) {
							resourceAllocation.extendedPlannerStartDate = boardItem.operationsModel.board.extendedPlannerStartDate.toISOString();
							resourceAllocation.plannerStartDate = moment(resourceAllocation.extendedPlannerStartDate)
								.add(resourceAllocation.plannerMobilizationTime, 'minutes')
								.toISOString();
						}

						if (boardItem.operationsModel.board.extendedPlannerEndDate) {
							resourceAllocation.extendedPlannerEndDate = boardItem.operationsModel.board.extendedPlannerEndDate.toISOString();
							resourceAllocation.plannerEndDate = moment(resourceAllocation.extendedPlannerEndDate)
								.subtract(resourceAllocation.plannerDemobilizationTime, 'minutes')
								.toISOString();
						}

						//boardItem.setLabel(this.jointBoard.getLabelFromStop(stop, boardItem.itemView.model.attributes.size.height));
					}
				});
			}

			if (boardItem.operationsModel.itemType == JointItemTypes.ResourceBlock) {
				this.resourceBlocks.forEach((resourceBlock) => {
					if (resourceBlock.id == boardItem.operationsModel.resourceblock.id) {
						if (boardItem.operationsModel.board.plannerStartDate) {
							resourceBlock.plannerStartDate = boardItem.operationsModel.board.plannerStartDate.toISOString();
						}

						if (boardItem.operationsModel.board.plannerEndDate) {
							resourceBlock.plannerEndDate = boardItem.operationsModel.board.plannerEndDate.toISOString();
						}

						//boardItem.setLabel(this.jointBoard.getLabelFromBlock(resourceBlock, boardItem.itemView.model.attributes.size.height));
					}
				});
			}

			this.$emit('input', {
				resourceAllocations: this.resourceAllocations,
				resourceBlocks: this.resourceBlocks,
				resourceTypes: this.resourceTypes,
				resources: this.resources,
				statusItems: this.statusItems
			});
			boardItem.jointPopUp && boardItem.jointPopUp.updateContent(boardItem.operationsModel);
		},
		refresh() {
			this.setBoard();
		},
		async onResize() {
			this.setBoard();
		}
	}
};
</script>

const axis = {
	dayOfWeekWidth: 130,
	hourOfDayWidth: 60,
	bollardHeight: 34.25,
	bollardSeparator: 30
};

const colors = {
	paper: '#F7F8FC',
	grid: {
		dots: 'black',
		lines: '#C2C6D1',
		berthLimit: '#2233dc',
		dayLine: '#C2C6D1',
		currentTime: '#CC0001'
	},
	berthplanner: {
		invalid: 'red',
		invalid_border: 'red',
		modified: '#FEF6E5',
		modified_border: '#F9A700',
		block: '#DADDE0',
		block_border: 'transparent',
		booking: '#FEF6E5',
		booking_border: 'transparent'
	},
	stencil: '#F2F5F8'
};

const shapes = {
	berthplanner: {
		rounded: '0.5rem'
	},
	resourceplanner: {
		rounded: '0.5rem'
	}
};

export default {
	axis: axis,
	colors: colors,
	shapes: shapes
};
